<template>
    <div class="profile-container">
      <div class="profile-header">
        <!-- Sol Tarafta Avatar -->
        <img :src="user.avatar" alt="Profile Avatar" class="profile-avatar" />
  
        <!-- Ortada Kullanıcı Bilgileri -->
        <div class="user-details">
          <div class="user-id">
            <input
              type="text"
              :value="authUser.user._id"
              ref="userIdInput"
              readonly
              class="user-id-input"
            />
            <button @click="copyUserId" class="copy-button">{{ $t('Kopyala') }}</button>
          </div>
          <div class="username">{{ $t('Kullanıcı Adı') }} : {{ authUser.user.username }}</div>
          <div class="rakeback">{{ $t('Bakiye') }} : {{ authUser.user.balance }}</div>
        </div>
  
        <!-- Sağ Tarafta VIP Bilgileri -->
        <div class="vip-info">
          <img :src="user.vip_image" alt="VIP Level" class="vip-image" />
          <div class="vip-level">{{ user.vip_level }}</div>
        </div>
      </div>

      <div class="info-container">
      <div class="info-item">
        <div class="info-label">{{ $t('Toplam Çevrim') }}</div>
        <div class="info-value">{{ authUser.user.stats.bet }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">{{ $t('Toplam Kazanç') }}</div>
        <div class="info-value">{{ authUser.user.stats.won }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">{{ $t('Toplam Para Yatırma') }}</div>
        <div class="info-value">{{ authUser.user.stats.deposit }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">{{ $t('Toplam Para Çekme') }}</div>
        <div class="info-value">{{ authUser.user.stats.withdraw }}</div>
      </div>
    </div>
  
      <!-- 4 Yan Yana Buton -->
      <div class="button-container">
      <!-- İlk Buton (SVG) -->
      <div class="button-item" @click="navigateTo(topButtons[0].route)">
        <svg width="52" height="54" viewBox="0 0 72 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="PC/H5æ¸¸æç±»åicon">
            <g id="Union">
              <path d="M5.10791 6.11938C5.10791 4.04832 6.78684 2.36938 8.85791 2.36938H39.4579C41.529 2.36938 43.2079 4.04832 43.2079 6.11938C43.2079 8.19045 41.529 9.86938 39.4579 9.86938H8.85791C6.78684 9.86938 5.10791 8.19045 5.10791 6.11938Z" fill="#FFD700"></path>
              <path d="M5.10791 16.6194C5.10791 14.5483 6.78684 12.8694 8.85791 12.8694H39.4579C41.529 12.8694 43.2079 14.5483 43.2079 16.6194C43.2079 17.0758 43.1264 17.5131 42.9771 17.9177C40.9416 18.4708 39.0208 19.3026 37.2582 20.3694H8.85791C6.78684 20.3694 5.10791 18.6905 5.10791 16.6194Z" fill="#FFD700"></path>
              <path d="M30.0078 51.8694C28.5442 49.6155 27.4733 47.0832 26.8917 44.3694H8.85791C6.78684 44.3694 5.10791 46.0483 5.10791 48.1194C5.10791 50.1905 6.78684 51.8694 8.85791 51.8694H30.0078Z" fill="#FFD700"></path>
              <path d="M26.3921 39.6321C26.3921 37.6401 26.6509 35.7086 27.1368 33.8694H8.85791C6.78684 33.8694 5.10791 35.5483 5.10791 37.6194C5.10791 39.6905 6.78684 41.3694 8.85791 41.3694H26.4582C26.4144 40.796 26.3921 40.2167 26.3921 39.6321Z" fill="#FFD700"></path>
              <path d="M28.1622 30.8694C29.3673 28.0222 31.1418 25.4746 33.3429 23.3694H8.85791C6.78684 23.3694 5.10791 25.0483 5.10791 27.1194C5.10791 29.1905 6.78684 30.8694 8.85791 30.8694H28.1622Z" fill="#FFD700"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M66.8921 39.6306C66.8921 49.5717 58.8332 57.6306 48.8921 57.6306C38.951 57.6306 30.8921 49.5717 30.8921 39.6306C30.8921 29.6895 38.951 21.6306 48.8921 21.6306C58.8332 21.6306 66.8921 29.6895 66.8921 39.6306ZM47.4584 44.4256C46.6549 45.1733 45.5776 45.6306 44.3936 45.6306C41.9083 45.6306 39.8936 43.6159 39.8936 41.1306C39.8936 38.3878 43.1221 35.6875 45.871 33.3885C47.074 32.3823 48.1851 31.453 48.8936 30.6306C49.4033 31.1403 50.1606 31.7844 51.0233 32.518C53.8806 34.9479 57.8936 38.3605 57.8936 41.1306C57.8936 43.6159 55.8788 45.6306 53.3936 45.6306C52.2613 45.6306 51.2268 45.2125 50.4359 44.5222C50.5764 45.6586 50.9884 46.3802 51.8936 47.1306V48.6306H45.8936V47.1306C46.8961 46.2005 47.3469 45.4398 47.4584 44.4256Z" fill="#FFD700"></path>
            </g>
          </g>
        </svg>
        <div class="button-text">{{ topButtons[0].text }}</div>
      </div>

      
      <!-- 2. Buton (SVG) -->
      <div class="button-item" @click="navigateTo(topButtons[1].route)">
        <svg width="54" height="54" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.60749 5.4845C7.60749 6.39818 6.86681 7.13886 5.95312 7.13886C5.03944 7.13886 4.29876 6.39818 4.29876 5.4845C4.29876 4.57082 5.03944 3.83013 5.95312 3.83013C6.86681 3.83013 7.60749 4.57082 7.60749 5.4845Z" fill="#FFD700"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.902 3.9822C18.6167 2.36583 17.2122 1.1875 15.5709 1.1875H5.17964C3.5383 1.1875 2.13382 2.36583 1.84858 3.9822L0.505521 11.5929C0.140005 13.6641 1.73332 15.5633 3.83658 15.5633H4.03737C5.57995 15.5633 6.92714 14.5196 7.31259 13.0259L7.6125 11.8638C7.80523 11.117 8.47882 10.5952 9.25011 10.5952H11.5004C12.2717 10.5952 12.9453 11.117 13.1381 11.8638L13.438 13.0259C13.6689 13.9208 14.245 14.6541 15.0014 15.0982C15.0008 15.0657 15.0005 15.0331 15.0005 15.0004C15.0005 12.2511 17.2194 10.0201 19.964 10.0005L18.902 3.9822ZM14.99 4.67564C15.4571 4.67564 15.8357 4.29704 15.8357 3.83001C15.8357 3.36298 15.4571 2.98438 14.99 2.98438C14.523 2.98438 14.1444 3.36298 14.1444 3.83001C14.1444 4.29704 14.523 4.67564 14.99 4.67564ZM14.99 8.05817C15.4571 8.05817 15.8357 7.67957 15.8357 7.21254C15.8357 6.74551 15.4571 6.36691 14.99 6.36691C14.523 6.36691 14.1444 6.74551 14.1444 7.21254C14.1444 7.67957 14.523 8.05817 14.99 8.05817ZM14.1444 5.52127C14.1444 5.9883 13.7658 6.36691 13.2988 6.36691C12.8317 6.36691 12.4531 5.9883 12.4531 5.52127C12.4531 5.05424 12.8317 4.67564 13.2988 4.67564C13.7658 4.67564 14.1444 5.05424 14.1444 5.52127ZM16.6813 6.36691C17.1483 6.36691 17.5269 5.9883 17.5269 5.52127C17.5269 5.05424 17.1483 4.67564 16.6813 4.67564C16.2143 4.67564 15.8357 5.05424 15.8357 5.52127C15.8357 5.9883 16.2143 6.36691 16.6813 6.36691ZM8.45312 5.4845C8.45312 6.86521 7.33384 7.9845 5.95312 7.9845C4.57241 7.9845 3.45312 6.86521 3.45312 5.4845C3.45312 4.10379 4.57241 2.9845 5.95312 2.9845C7.33384 2.9845 8.45312 4.10379 8.45312 5.4845Z" fill="#FFD700"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0005 14.9004C24.0005 17.1095 22.2096 18.9004 20.0005 18.9004C17.7913 18.9004 16.0005 17.1095 16.0005 14.9004C16.0005 12.6913 17.7913 10.9004 20.0005 10.9004C22.2096 10.9004 24.0005 12.6913 24.0005 14.9004ZM19.682 15.9659C19.5034 16.1321 19.264 16.2337 19.0009 16.2337C18.4486 16.2337 18.0009 15.786 18.0009 15.2337C18.0009 14.6242 18.7183 14.0241 19.3292 13.5132C19.5965 13.2897 19.8435 13.0831 20.0009 12.9004C20.1142 13.0137 20.2825 13.1568 20.4742 13.3198C21.1091 13.8598 22.0009 14.6181 22.0009 15.2337C22.0009 15.786 21.5532 16.2337 21.0009 16.2337C20.7493 16.2337 20.5194 16.1408 20.3436 15.9874C20.3749 16.2399 20.4664 16.4003 20.6676 16.567V16.9004H19.3342V16.567C19.557 16.3604 19.6572 16.1913 19.682 15.9659Z" fill="#FFD700"></path>
        </svg>
        <div class="button-text">{{ topButtons[1].text }}</div>
      </div>

        <!-- Üçüncü Buton (Sarı Renk) -->
    <div class="button-item" @click="navigateTo(topButtons[2].route)">
      <svg width="54" height="54" viewBox="0 0 24 20" fill="#FFD700" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.19141 0.549805C6.63912 0.549805 6.19141 0.99752 6.19141 1.5498C6.19141 2.10209 6.63912 2.5498 7.19141 2.5498H11.1914C11.7437 2.5498 12.1914 2.10209 12.1914 1.5498C12.1914 0.99752 11.7437 0.549805 11.1914 0.549805H7.19141Z" fill="#FFD700"></path>
        <path data-v-6a527e46="" fill-rule="evenodd" clip-rule="evenodd" d="M4.19141 1.5498H5.19157C5.19157 2.65437 6.087 3.5498 7.19157 3.5498H11.1916C12.2961 3.5498 13.1916 2.65437 13.1916 1.5498H14.1914C15.296 1.5498 16.1914 2.44524 16.1914 3.5498V7.46324C16.0575 7.45431 15.9223 7.44977 15.7861 7.44977C12.4724 7.44977 9.78613 10.1361 9.78613 13.4498C9.78613 15.603 10.9204 17.4913 12.6239 18.5498H4.19141C3.08684 18.5498 2.19141 17.6544 2.19141 16.5498V3.5498C2.19141 2.44524 3.08684 1.5498 4.19141 1.5498ZM4.54883 5.7373C4.13461 5.7373 3.79883 6.07309 3.79883 6.4873C3.79883 6.90152 4.13461 7.2373 4.54883 7.2373H12.5488C12.963 7.2373 13.2988 6.90152 13.2988 6.4873C13.2988 6.07309 12.963 5.7373 12.5488 5.7373H4.54883ZM3.79883 9.67578C3.79883 9.26157 4.13461 8.92578 4.54883 8.92578H9.19141C9.60562 8.92578 9.94141 9.26157 9.94141 9.67578C9.94141 10.09 9.60562 10.4258 9.19141 10.4258H4.54883C4.13461 10.4258 3.79883 10.09 3.79883 9.67578Z" fill="#FFD700"></path>
        <path data-v-6a527e46="" fill-rule="evenodd" clip-rule="evenodd" d="M15.5861 18.2758C16.7048 18.2758 17.734 17.8931 18.5502 17.2516L20.529 19.2304C20.8219 19.5233 21.2967 19.5233 21.5896 19.2304C21.8825 18.9375 21.8825 18.4626 21.5896 18.1697L19.5716 16.1517C20.086 15.3872 20.3861 14.4665 20.3861 13.4758C20.3861 10.8248 18.2371 8.67578 15.5861 8.67578C12.9352 8.67578 10.7861 10.8248 10.7861 13.4758C10.7861 16.1267 12.9352 18.2758 15.5861 18.2758ZM15.5861 16.7758C17.4087 16.7758 18.8861 15.2983 18.8861 13.4758C18.8861 11.6532 17.4087 10.1758 15.5861 10.1758C13.7636 10.1758 12.2861 11.6532 12.2861 13.4758C12.2861 15.2983 13.7636 16.7758 15.5861 16.7758Z" fill="#FFD700"></path>
    </svg>
    <div class="button-text">{{ topButtons[2].text }}</div>
    </div>

    <div class="button-item" @click="navigateTo(topButtons[3].route)">
      <svg width="54" height="54" viewBox="0 0 24 20" fill="#FFD700" xmlns="http://www.w3.org/2000/svg">
        <path data-v-6a527e46="" d="M1.25 3.72803C1.25 2.62346 2.14543 1.72803 3.25 1.72803H18.25C19.3546 1.72803 20.25 2.62346 20.25 3.72803V5.22803H1.25V3.72803Z" fill="#FFD700"></path>
        <path data-v-6a527e46="" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 7.00006H15.8018C12.9928 8.08352 11 10.8091 11 14.0001C11 15.4706 11.4232 16.8424 12.1546 18.0001H3.25C2.14543 18.0001 1.25 17.1046 1.25 16.0001V7.00006ZM4.125 9.37506C3.71079 9.37506 3.375 9.71085 3.375 10.1251C3.375 10.5393 3.71079 10.8751 4.125 10.8751H7.125C7.53921 10.8751 7.875 10.5393 7.875 10.1251C7.875 9.71085 7.53921 9.37506 7.125 9.37506H4.125Z" fill="FFD700"></path>
        <path data-v-6a527e46="" fill-rule="evenodd" clip-rule="evenodd" d="M15.2896 10.3317L13.7232 10.0622C13.222 10.6379 12.8288 11.3101 12.5752 12.0472L13.5936 13.2711C13.5568 13.5084 13.5377 13.7516 13.5377 13.9992C13.5377 14.2473 13.5568 14.4909 13.5938 14.7287L12.5752 15.9529C12.8288 16.69 13.222 17.3622 13.7232 17.9379L15.2914 17.6681C15.6685 17.9728 16.0936 18.2205 16.5535 18.3982L17.1037 19.8906C17.4748 19.9624 17.8581 20.0001 18.2502 20.0001C18.6422 20.0001 19.0254 19.9625 19.3964 19.8907L19.946 18.4C20.4074 18.2222 20.8337 17.9741 21.2119 17.6686L22.7773 17.938C23.2784 17.3623 23.6716 16.6902 23.9252 15.9532L22.9095 14.7326C22.9469 14.4936 22.9662 14.2487 22.9662 13.9992C22.9662 13.7502 22.9469 13.5058 22.9098 13.2673L23.9252 12.0469C23.6715 11.3099 23.2784 10.6378 22.7773 10.0621L21.2136 10.3312C20.8348 10.0249 20.4076 9.77616 19.9453 9.59815L19.3964 8.10941C19.0254 8.03765 18.6422 8.00006 18.2502 8.00006C17.8581 8.00006 17.4748 8.03767 17.1037 8.10949L16.5542 9.59987C16.0933 9.77786 15.6674 10.0262 15.2896 10.3317ZM21.4029 13.9993C21.4029 15.7405 19.9914 17.1521 18.2502 17.1521C16.509 17.1521 15.0974 15.7405 15.0974 13.9993C15.0974 12.2581 16.509 10.8465 18.2502 10.8465C19.9914 10.8465 21.4029 12.2581 21.4029 13.9993Z" fill="FFD700"></path>

      </svg>
    <div class="button-text">{{ topButtons[3].text }}</div>
    </div>
    


      <!-- Diğer 3 Buton -->
     
    </div>
  
      <!-- 8 Alt Alta Buton -->
      <div class="vertical-button-container">
        <div class="vertical-button-item" v-for="button in bottomButtons" :key="button.id" @click="navigateTo(button.route)">
          <img :src="button.image" :alt="button.text" class="vertical-button-image" />
          <div class="vertical-button-text">{{ button.text }}</div>
        </div>
      </div>
    </div>
  </template>


<script>
import { mapGetters, mapActions } from 'vuex';
import AvatarImage from '@/components/AvatarImage';
export default {
  data() {
    return {
      user: {
        avatar: require('@/assets/img/ranks/admin.svg'), // Örnek avatar URL'i
        user_id: '',
        username: '',
        rakeback: 5,
        vip_image: 'https://static.vecteezy.com/system/resources/previews/035/234/043/non_2x/3d-icon-verify-account-png.png', // Örnek VIP resmi URL'i
        vip_level: 'Gold'
      },
      totalSpins: '', // Toplam Çevrim
      totalDeposit: '', // Toplam Para Yatırma
      totalWithdrawal: '', // Toplam Para Çekme
      topButtons: [
        {
          id: 1,
          image: 'https://via.placeholder.com/50', // Buton 1 resmi
          text: this.$t('Bonus Geçmişi'),
          route: '/profile'
        },
        {
          id: 2,
          image: 'https://via.placeholder.com/50', // Buton 2 resmi
          text: this.$t('Oyun Geçmişi'),
          route: '/gamehistory'
        },
        {
          id: 3,
          image: 'https://via.placeholder.com/50', // Buton 3 resmi
          text: this.$t('İşlem Geçmişi'),
          route: '/transactions'
        },
        {
          id: 4,
          image: 'https://via.placeholder.com/50', // Buton 4 resmi
          text: this.$t('Hesap Bilgileri'),
          route: '/userprofile'
        }
      ],
      bottomButtons: [
      {
          id: 8,
          image: require('@/assets/img/profile/1/4.png'), // Buton 8 resmi
          text: this.$t('Bildirimler'),
          route: '/news'
        },
        {
          id: 5,
          image: require('@/assets/img/profile/1/1.png'), // Buton 5 resmi
          text: this.$t('Takımım'),
          route: '/affiliates'
        },
        {
          id: 6,
          image: require('@/assets/img/profile/1/2.png'), // Buton 6 resmi
          text: this.$t('Promosyonlar'),
          route: '/promotions'
        },
        {
          id: 7,
          image: require('@/assets/img/profile/1/3.png'), // Buton 7 resmi
          text: this.$t('Ödüller'),
          route: '/rewards'
        },
        
        {
          id: 9,
          image: require('@/assets/img/profile/1/5.png'), // Buton 9 resmi
          text: this.$t('Müşteri Hizmetleri'),
          route: '/helper'
        },
        {
          id: 10,
          image: require('@/assets/img/profile/1/6.png'), // Buton 10 resmi
          text: this.$t('Uygulama indir'),
          route: '/profile'
        }
       
        
      ]
    };
  },
 
        components: {
            AvatarImage
        },
        computed: {
            ...mapGetters([
                'authUser'
            ])
        },
  methods: {
    generateRandomAvatar() {
      const seed = Math.random().toString(36).substring(7); // Rastgele bir seed oluştur
      this.user.avatar = `https://avatars.dicebear.com/api/bottts/${seed}.svg`;
    },
    ...mapActions([
                'notificationShow'
            ]),
    copyUserId() {
      const input = this.$refs.userIdInput;
      input.select();
      document.execCommand('copy');
      alert('Kullanıcı ID kopyalandı: ' + input.value);
    },
    navigateTo(route) {
      this.$router.push(route); // Vue Router ile yönlendirme
    }
  }
};
</script>


<style scoped>
.profile-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #233933;
  border: 1px solid rgba(91, 104, 20, 0.35);
  border-radius: 20px;
}

.profile-header {
  display: flex;
  align-items: center;
  background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-details {
  flex: 1; /* Kullanıcı bilgileri alanını genişletir */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-id, .username, .rakeback {
  margin: 5px 0;
}

.user-id {
  display: flex;
  align-items: center;
}

.user-id-input {
  font-size: 1.2em;
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  margin-right: 10px;
  width: 100px; /* Input genişliği */
}

.copy-button {
  background-color: #4CAF50; /* Yeşil renk */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.copy-button:hover {
  background-color: #45a049; /* Hover rengi */
}

.username {
  font-size: 1.1em;
  color: #fff;
}

.rakeback {
  font-size: 1em;
  color: #fff;
}

.vip-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.vip-image {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.vip-level {
  font-size: 1.2em;
  font-weight: bold;
  color: #d4af37; /* Gold rengi */
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px;
  background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
  border-radius: 10px;
}

.info-item {
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.info-label {
  font-size: 0.9em;
  color: #fff;
  margin-bottom: 5px;
}

.info-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #FFD700; /* Sarı renk */
}

/* 4 Yan Yana Buton */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-item {
  text-align: center;
  cursor: pointer;
  flex: 1;
  margin: 0 10px;
  padding: 10px;
  background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.button-item:hover {
  background-color: #34495e; /* Hover rengi */
}

.button-image {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.button-text {
  font-size: 0.9em;
  color: #fff;
}

/* 8 Alt Alta Buton */
.vertical-button-container {
  margin-top: 20px;
}

.vertical-button-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.vertical-button-item:hover {
  background-color: #34495e; /* Hover rengi */
}

.vertical-button-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.vertical-button-text {
  font-size: 0.9em;
  color: #fff;
}

/* Mobil Görünüm */
@media (max-width: 768px) {
  .profile-header {
    padding: 15px; /* Mobilde padding biraz küçültülebilir */
  }

  .profile-avatar {
    width: 80px; /* Mobilde avatar boyutu küçültülebilir */
    height: 80px;
    margin-right: 15px;
  }

  .vip-image {
    width: 40px; /* Mobilde VIP resmi boyutu küçültülebilir */
    height: 40px;
  }

  .user-id-input {
    font-size: 1em; /* Mobilde yazı boyutu küçültülebilir */
    width: 80px; /* Mobilde input genişliği */
  }

  .copy-button {
    font-size: 0.8em; /* Mobilde buton yazı boyutu küçültülebilir */
    padding: 4px 8px;
  }

  .username {
    font-size: 0.9em;
  }

  .rakeback {
    font-size: 0.9em;
  }

  .vip-level {
    font-size: 1em;
  }

  .info-container {
    flex-wrap: wrap; /* Mobilde alt alta sırala */
  }

  .info-item {
    flex: 1 1 45%; /* Mobilde 2'li sırala */
    margin: 10px 0;
  }


  .button-container {
    flex-wrap: wrap; /* Mobilde butonlar alt alta gelsin */
  }

  .button-item {
    flex: 1 1 45%; /* Mobilde iki buton yan yana */
    margin: 5px;
  }

  .vertical-button-item {
    padding: 8px; /* Mobilde padding küçültülebilir */
  }

 

  .vertical-button-text {
    font-size: 0.8em; /* Mobilde yazı boyutu küçültülebilir */
  }
}
</style>